<script setup>
  const { code, hero } = defineProps({
    code: { required: true, type: String },
    hero: { default: false, type: Boolean }
  })

  const { findOne } = useStrapi4()

  const page = reactive({
    ...(await useAsyncData(`page-${code}`, async () => {
      const res = await findOne('pages', code, {
        fields: ['code', 'content', 'subtitle', 'title', 'updatedAt'],
        populate: {
          cover: { fields: ['url'] },
          seo: {
            fields: ['metaDescription', 'metaRobots', 'metaTitle', 'structuredData'],
            populate: {
              metaImage: { fields: ['url'] }
            }
          }
        }
      })
      return res.data
    }))
  })

  if (page.data?.seo) {
    useSeo(page.data.seo)
  }
</script>

<template>
  <div>
    <Loading class="mx-auto max-w-screen-xl px-4 py-16" v-if="page.pending" />
    <Error class="mx-auto max-w-screen-xl px-4 py-16" v-else-if="page.error" :error="page.error" :retry="page.refresh" />
    <template v-else>
      <PageHero v-if="hero" :page="page.data" />
      <slot :page="page.data" />
    </template>
  </div>
</template>
