<script setup>
  const { page } = defineProps({
    page: { required: true, type: Object }
  })

  const title = computed(() => {
    return page.code === 'home' ? page.subtitle.split('|') : page.title
  })

  const toggle = ref(true)
  let interval = null

  onMounted(() => {
    interval = setInterval(() => {
      toggle.value = !toggle.value
    }, 2000)
  })

  onUnmounted(() => {
    if (interval) clearInterval(interval)
  })
</script>

<template>
  <div
    class="relative h-60 overflow-x-hidden bg-cover bg-center sm:h-80"
    :style="{
      backgroundImage: page.cover ? `url('${useStrapiMedia(page.cover.url)}')` : 'none'
    }"
  >
    <div class="absolute inset-0 h-full w-full bg-black/50"></div>
    <div class="relative mx-auto flex h-full max-w-screen-xl flex-col items-center justify-center px-4 text-center text-white">
      <template v-if="page.code === 'home'">
        <h1 class="flex flex-col font-special text-2xl font-bold sm:text-4xl md:text-5xl">
          <div v-text="title[0]" />
          <div class="relative flex flex-col items-center">
            <div
              class="duration-500"
              v-text="title[1]"
              :class="{
                'scale-y-0 opacity-0': !toggle,
                'scale-y-100 opacity-100': toggle
              }"
            />
            <div
              class="absolute duration-500"
              v-text="title[2]"
              :class="{
                'scale-y-0 opacity-0': toggle,
                'scale-y-100 opacity-100': !toggle
              }"
            />
          </div>
        </h1>
        <div class="mt-4 sm:mt-8">
          <NuxtLink to="/jobs">
            <Button size="lg">Voir les jobs</Button>
          </NuxtLink>
        </div>
      </template>
      <template v-else>
        <h1 class="font-special text-3xl font-bold sm:text-4xl md:text-5xl" v-text="title" />
        <div class="mt-4 text-xl text-gorse sm:text-2xl md:text-3xl" v-if="page.subtitle" v-text="page.subtitle" />
      </template>
    </div>
  </div>
</template>
